import * as React from "react"
import { Link } from 'gatsby'

import { Container, Item, ContactForm } from '../../../components'

import styles from './form.module.scss';

import contact_2 from '../../../assets/images/contact_3.svg'

// markup
export const Form = () => {

    // const { site } = useStaticQuery(query)
    // const {
    //     appUrl
    // } = site.siteMetadata

    return (
        <div className={styles.core}>
            <Container>
                <h3 className={styles.title}>
                    Contacta con nosotros
                </h3>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <p className={styles.text}>Nuestro equipo estará encantado de hablar contigo y ayudarte.</p>
                    </div>
                    <div className={styles.body}>
                        <div className="columns is-desktop">
                            <div className="column">
                                <div className={styles.subtitle}>¿Cómo te podemos ayudar?</div>
                                <div className={styles.text}>
                                    En los FAQs (que encontraras en la <Link to={"/"} className={styles.link}>home</Link>) resolvemos las dudas más comunes.
                                    <br/>Pero...
                                    <br/> <br/>¿Sigues teniendo dudas?
                                    <br/> ¿Quieres un plan personalizado?
                                    <br/> ¿O simplemente charlar un rato? <br/>
                                    <br/> Envianos un correo (<a href="mailto:hola@calendab.com?subject=Hola!" className={styles.link}>hola@calendab.com</a>) o rellena el formulario, y cuéntanos lo que tienes en la cabeza.
                                </div>
                                <img src={contact_2} style={{'--width': '350px'}} className={styles.img} width="350px" height="280px" alt="Contacta con nosotros" />
                            </div>
                            <div className="column">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
    

// const query = graphql`
//     query Grow {
//         site {
//             siteMetadata {
//                 appUrl: appUrl
//             }
//         }
//     }
// `