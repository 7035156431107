import * as React from "react"

import { DefaultLayout } from "../../layouts";
import { SEO, Call } from "../../components";

// import { Form } from './components'
import { Form } from '../../page_components/contacto'

import styles from './contacto.module.scss';

// markup
const Contacto = () => {
    return (
        <DefaultLayout>
            <SEO title="Calendab · Contacto" />
            <div className={styles.container}>
                <div className={styles.content}>
                    <Form />
                    <Call />
                </div>
            </div>
        </DefaultLayout>
    )
}
    
export default Contacto
    